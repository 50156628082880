import _merge from 'lodash/merge';

import Localization from '@web-solutions/module-localization';
import { initLocalization as initFR } from '@web-solutions/face-reading';
import { initLocalization as initMS } from '@web-solutions/manage-subscription';
import HABIO from '@web-solutions/habio/src/localization/locales/en/index.json';

import { APP_NAME, SUPPORT_EMAIL } from 'src/constants/general';

const resources = {
  de: {
    translation: require('./locales/de/index.json'),
  },
  en: {
    translation: require('./locales/en/index.json'),
  },
  'es-mx': {
    translation: require('./locales/es-MX/index.json'),
  },
  es: {
    translation: require('./locales/es-ES/index.json'),
  },
  fr: {
    translation: require('./locales/fr/index.json'),
  },
  'pt-br': {
    translation: require('./locales/pt-BR/index.json'),
  },
  pt: {
    translation: require('./locales/pt-PT/index.json'),
  },
  ja: {
    translation: require('./locales/ja/index.json'),
  },
  ar: {
    translation: require('./locales/ar/index.json'),
  },
}

const experimentalResources = {
  en: {
    translation: _merge({}, HABIO, require('./locales/experimental/en.json')),
  },
}

const noProofReadResources = {
  de: {
    translation: require('./locales/de/no_proofread.json'),
  },
  en: {
    translation: require('./locales/en/no_proofread.json'),
  },
  es: {
    translation: require('./locales/es-ES/no_proofread.json'),
  },
  fr: {
    translation: require('./locales/fr/no_proofread.json'),
  },
  'pt-br': {
    translation: require('./locales/pt-BR/no_proofread.json'),
  },
  pt: {
    translation: require('./locales/pt-PT/no_proofread.json'),
  },
  ja: {
    translation: require('./locales/ja/no_proofread.json'),
  },
  ar: {
    translation: require('./locales/ar/no_proofread.json'),
  },
}

const resultResources = _merge({}, resources, experimentalResources, noProofReadResources);

const resourcesManSub = {
  en: require('./locales/en/manage.json'),
  de: require('./locales/de/manage.json'),
  es: require('./locales/es-ES/manage.json'),
  fr: require('./locales/fr/manage.json'),
  'pt-br': require('./locales/pt-BR/manage.json'),
  pt: require('./locales/pt-PT/manage.json'),
  ja: require('./locales/ja/manage.json'),
  ar: require('./locales/ar/manage.json'),
};

export function initLocalization() {
  Localization.init(resultResources, { appName: APP_NAME, supportEmail: SUPPORT_EMAIL });
  initFR();
  initMS(resourcesManSub);
};


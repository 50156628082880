import { ThunkAction } from 'redux-thunk';

//@ts-ignore
import Billing from '@web-solutions/module-billing';

import Analytics from '@web-solutions/module-analytics';

import { setApiAuth } from 'core/utils/network';
import { stringifyUrlParams } from 'core/utils/url-sync';

import { auth as authRequest } from 'src/api/auth';

import { setPending } from '../app/actions';

import { profileSlice } from '.';

export const {
  setAge,
  setAuthorized,
  setDiabetes,
  setEmail,
  setGender,
  setPregnant,
  setStressMeasure,
  resetEmail,

} = profileSlice.actions

export const processAge = (age: string): ThunkAction<void, any, unknown, any> => (dispatch, getState) => {
  Analytics.setUserProperty('age', age);
  dispatch(setAge(age));
}

export const processGender = (gender: string): ThunkAction<void, any, unknown, any> => (dispatch, getState) => {
  Analytics.setUserProperty('gender', gender);
  dispatch(setGender(gender));
}

export const processPregnant = (pregnant: string): ThunkAction<void, any, unknown, any> => (dispatch, getState) => {
  dispatch(setPregnant(pregnant));
}

export const processDiabetes = (diabetes: string): ThunkAction<void, any, unknown, any> => (dispatch, getState) => {
  dispatch(setDiabetes(diabetes));
}

export const auth = (): ThunkAction<Promise<any>, any, unknown, any> => async (dispatch, getState) => {
  return authRequest()
    .then((res: any) => {
      setApiAuth(`${res.token_type} ${res.access_token}`);
      Billing.setApiAuth(`${res.token_type} ${res.access_token}`);
      dispatch(setAuthorized(res));
    });
}

export const authAndSetUserData = (isSetPending?: boolean): ThunkAction<Promise<void>, any, unknown, any> => async (dispatch) => {
  if (isSetPending) {
    dispatch(setPending(true));
  }

  try {
    await dispatch(auth());
  }
  catch (error: any) {
    if (error?.status !== 422) {
      throw error;
    }
    console.log('[ERROR AUTH]', error);
  }
  finally {
    if (isSetPending) {
      dispatch(setPending(false));
    }
  }
};

export const processEmail = (email: string, options?: { skipUrlSync: boolean }): ThunkAction<void, any, unknown, any> =>
  (dispatch, getState) => {
    if (email !== getState().profile.email) {
      Analytics.setUserProperty('email', email);
      Analytics.trackEvent('user', 'info', { email, });
      dispatch(setEmail(email));
      if (!options?.skipUrlSync) {
        stringifyUrlParams({ email })
      }
    }
  }

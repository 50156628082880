import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ProfileState {
  authorized: boolean;
  apiAuth: Record<string, string> | null;
  email: string;
  gender: string | null;
  age: string | null;
  pregnant: string | null;
  stressMeasure: string | null;
  diabetes: string | null;
}


const initialState: ProfileState = {
  authorized: false,
  apiAuth: null,
  email: '',
  gender: null,
  age: null,
  pregnant: null,
  stressMeasure: null,
  diabetes: null,
};

const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setAge: (state, action: PayloadAction<string>) => {
      state.age = action.payload;
    },
    setGender: (state, action: PayloadAction<string>) => {
      state.gender = action.payload;
    },
    setAuthorized: (state, action: PayloadAction<Record<string, string>>) => {
      state.authorized = true;
      state.apiAuth = action.payload;
    },
    setPregnant: (state, action: PayloadAction<string>) => {
      state.pregnant = action.payload;
    },
    setDiabetes: (state, action: PayloadAction<string>) => {
      state.diabetes = action.payload;
    },
    setStressMeasure: (state, action: PayloadAction<string>) => {
      state.stressMeasure = action.payload;
    },
    resetEmail: (state) => {
      state.email = initialState.email;
    }
  }
})

export const blacklist = ['authorized'];

export { slice as profileSlice };


